
export default {
  name: 'CardDefault',
  props: {
    cardDefault: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    itemLabel(label) {
      if (label.includes('*')) {
        label = label.replace('*', ''); // Remove the asterisk
        return `${label} <span class="new-menu">yeni</span>`; // Add the new tag
      }
      return label;
    }
  }
}
