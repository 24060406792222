
export default {
  name: 'CardSubscribe',
  data() {
    return {
      seo: {
        title: '',
        BirincilKampanya: 'Default-Campaign',
      },
      form: {
        fullname: '',
        company: '',
        email: '',
        phone: '',
        countryCode: '',
        bulten_check : false,
        privacy_check: false,
        campaign_check: false,
       

      },
      isInvalid: {
        fullname: false,
        company: false,
        email: false,
        phone: false,
        bulten_check : false,
        privacy_check: false,
        campaign_check: false,
       
      },
      success: false,
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default:
        'Blog sayfamıza abone olarak yeni içeriklerden haberdar olabilirsin!',
    },
    inputPlaceholder: {
      type: String,
      default: 'E-postanızı yazınız',
    },
    buttonLabel: {
      type: String,
      default: 'Gönder',
    },
    bultenText: {
      type: String,
      default:
        'Blog bülten aboneliği kapsamında işlenecek kişisel verileriniz ile ilgili detaylı bilgiye <a href="https://www.mikro.com.tr/yasal/#kisisel-veriler-aydinlatma-metni" target="_blank">Aydınlatma Metninini</a> okudum',
    },
    privacyText: {
      type: String,
      default:
        '<a href="https://www.mikro.com.tr/yasal/#kisisel-veriler-aydinlatma-metni" target="_blank">Aydınlatma Metnini</a> uyarınca, kişisel verilerimin aktarılmasına Açık Rıza Metni kapsamında açık rıza veriyorum.',
    },
    campaignText: {
      type: String,
      default:
              'Ticari elektronik ileti gönderimi için <a href="https://www.mikro.com.tr/yasal/#kisisel-veriler-aydinlatma-metni" target="_blank">Açık Rıza Metni</a> kapsamında, e-posta adresimin yurt dışına aktarılmasına onay veriyorum.'
    },
    
    placeholderEmail: {
      type: String,
      default: 'E-postanızı yazınız',
    },

  },

  methods: {
    isEmail(e) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(String(e))
    },

    submitForm() {
      try {
        if (this.checkAllFields() === true) {
          this.success = true

          let data = {
            email: this.form.email,
            privacy_check: this.form.privacy_check,
            
          }

          // if (process.client) {
          //   const sfData = this.prepareSFData()
          //   data = Object.assign(data, sfData)
          // }

          try {
            // Data Layer for Google Tag Manager
            let dataLayerObject = {
              event: "newsletter_subscription",
              email: data.email
            };
            window.dataLayer.push(dataLayerObject);

            this.$axios.post('/subscribe-forms', data)
              .then((response) => {
                console.log("API Success", response.data)
                
              })
              .catch((error) => {
                console.error("API Error", error)
              })
          } catch (error) {
            console.error("API Error:", error)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },

    // prepareSFData function remains the same
    // prepareSFData() {
    //   // ... existing prepareSFData code ...
    //   return {};
    // },



    checkAllFields() {
      this.isInvalid.email = this.isEmail(this.form.email)
      this.isInvalid.privacy_check = this.form.privacy_check

      this.isInvalid.email === false
        ? document.getElementById('email').classList.add('is-invalid')
        : document.getElementById('email').classList.remove('is-invalid')

      this.isInvalid.bulten_check === false
        ? document.querySelector('input[name="bulten_check"]').parentElement.parentElement.classList.add('is-invalid')
        : document.querySelector('input[name="bulten_check"]').parentElement.parentElement.classList.remove('is-invalid')

        this.isInvalid.privacy_check === false
        ? document.querySelector('input[name="privacy_check"]').parentElement.parentElement.classList.add('is-invalid')
        : document.querySelector('input[name="privacy_check"]').parentElement.parentElement.classList.remove('is-invalid')

        this.isInvalid.campaign_check === false
        ? document.querySelector('input[name="campaign_check"]').parentElement.parentElement.classList.add('is-invalid')
        : document.querySelector('input[name="campaign_check"]').parentElement.parentElement.classList.remove('is-invalid')

      return (
        this.isEmail(this.form.email) === true &&
        this.form.privacy_check === true
      )
    },
  },
}
